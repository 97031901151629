<template>
  <q-markup-table wrap-cells dense flat separator="none">
    <thead></thead>
    <tbody>
      <template v-for="col in cols">
        <tr v-if="col.showInContext" :key="col.name" class="q-tr--no-hover">
          <td style="width: 200px; white-space: nowrap">
            {{ col.label }}
          </td>
          <td :class="col.__tdClass" :style="col.style">
            <slot
              :name="`markup-cell-${col.name}`"
              :col="col"
              :row="row"
              :value="col.value"
            >
              <pro-report-all-td-field
                :col="col"
                :loading="loading"
                :code-table-temp="codeTableTemp"
                :filter-option="filterOption"
                markupTableMode
              />
            </slot>
          </td>
        </tr>
      </template>
    </tbody>
  </q-markup-table>
</template>

<script>
import ProReportAllTdField from "@/components/PROSmart/Report/TableTd/ProReportAllTdField";

export default {
  name: "ProReportMarkupTable",
  props: {
    row: {
      type: Object,
    },
    cols: {
      type: Array,
      required: true,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    codeTableTemp: {
      type: Object,
      required: true,
    },
    filterOption: {},
  },

  components: { ProReportAllTdField },

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped></style>
