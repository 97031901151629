import { render, staticRenderFns } from "./ProReportMarkupTable.vue?vue&type=template&id=cc55573c&scoped=true&"
import script from "./ProReportMarkupTable.vue?vue&type=script&lang=js&"
export * from "./ProReportMarkupTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc55573c",
  null
  
)

export default component.exports
import QMarkupTable from 'quasar/src/components/markup-table/QMarkupTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QMarkupTable,QTr});
